//import { Routes, Route, BrowserRouter } from 'react-router-dom';
import '../App.css';
import Wraper from "./common/wraper";
import { Button , Terms , Input} from "./common"
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { useSuperfan } from '../context';

const Index = () =>{
    const nav = useNavigate();
    const {submitForm} = useSuperfan();
    const [info , setInfo] = useState({
        firstName : null,
        lastName : null,
        email : null,
        terms : false
    });


    return(
        <Wraper
            style={{
                display : "flex",
                justifyContent : "center",
                alignItems : "flex-end",
            }}
        >
        <div style={{
            backgroundColor: "#dea42b",
            height: "85svh",
            width : "90%",
            borderRadius: "15px 15px 0 0",
            color: "white",
            padding : "0 12%",
        }}>

            <div className='block' style={{
            height : "6svh"
            }}/>

            <div
                className='imgContainer-h'
                style={{
                    height : "14svh",
                    margin : "auto",
                }}
            >
                <img src='2d/logo.webp' className='logo'/>
            </div>

            <div className='block' style={{
            height : "3.5%"
            }}/>

            <h4
                style={{
                }}
            >
                Enter your details to<br/>
                start the game
            </h4>

            <div className='block' 
                style={{
                    height : "3.5%"
                }}
            />

            <Input
                placeholder = "First name"
                onChange = {(e)=>{
                    setInfo((prev)=>{
                        return {
                            ...prev,
                            firstName : e.target.value
                        }
                    })
                }}
            />
            
            <div className='block' 
                style={{
                    height : "1.5%"
                }}
            />

            <Input placeholder='Last name'
                onChange = {(e)=>{
                    setInfo((prev)=>{
                        return {
                            ...prev,
                            lastName : e.target.value
                        }
                    })
                }}
            />
            
            <div className='block' style={{
                height : "1.5%"
            }}/>
            
            <Input placeholder='Email'
                onChange = {(e)=>{
                    setInfo((prev)=>{
                        return {
                            ...prev,
                            email : e.target.value
                        }
                    })
                }}
            />

            <div className='block' style={{
            height : "3.5%"
            }}/>
         
            <Terms terms = {info?.terms} setInfo = {setInfo}/>

            <div className='block' style={{
                height : "5.5%"
            }}/>

            <Button 
                to = "/page3"
                _id = "registerPage"
                onClick = {()=>{
                    if(info?.firstName && info?.lastName && info?.email && info?.terms){
                        submitForm(info);
                        localStorage.setItem("collected", "true");
                        nav("/page3")
                    }
                    else{
                        alert("Please fill in all the details")
                    }
                    // localStorage.setItem("collected", "true");
                    // nav("/page3");
                }}    
            >
                BEGIN THE HUNT
            </Button>
        </div>
       
        
        </Wraper>
    )
}

export default Index;