import { useNavigate } from "react-router-dom"
import { useSuperfan } from "../../context";
const Button = ({
    // eslint-disable-next-line react/prop-types
    children,
    ...rest
})=>{
    const { style = {} , to = null , _id = null , onClick = null , className} = rest;
    const nav = useNavigate();
    const {
      recordEvent
    } = useSuperfan();
    return(
        <button
            className={className}
            style={{
                backgroundColor: "#CD450A",
                textTransform: "uppercase",
                paddingTop: "0.9rem",
                paddingBottom: "0.7rem",
                width : "12rem",
                color: "white",
                border : "2px solid #DEA42B",
                fontSize : "0.9rem",
                borderRadius : "10px",
                ...style
            }}
            id={_id}
            onClick={()=>{
                if(window.canplay){
                  document.querySelector("#button-click").play()
                }

                if(onClick){
                  onClick()
                  recordEvent(_id)
                }
                else{
                  to && nav(to)
                  recordEvent(_id)
                }
            }}
        >
            {children}
        </button>
    )
}

export const Button2 = ({text , onClick , _id = null ,...rest})=>{
  const {style = {} , className = ""} = rest;
  const {
    recordEvent
  } = useSuperfan();

  return (
      <button
          onClick={()=>{
              if(window.canplay){
                document.querySelector("#button-click").play()
              }
              onClick();
              recordEvent(_id);
          }}
          id={_id}
          className={className}
          style={{
              backgroundColor : "#CD450A",
              color : "white",
              borderRadius : "5px",
              border : "none",
              cursor : "pointer",
              width : "6.5rem",
              paddingTop : "0.6rem",
              paddingBottom : "0.4rem",
              display : "block",
              margin : "auto",
              ...style
          }}
      >
          {text}
      </button>
  )
}

export function Terms({ terms = false, setInfo = () => {} }) {
    return (
      <section
        style={{
          display: "flex",
        }}
        className="terms dontwantBold"
      >
        <div
          onClick={() => {
            setInfo((prev) => {
              return {
                ...prev,
                terms: !prev.terms,
              };
            });
          }}
        >
          <div
            id="terms"
            style={{
              width: "1rem",
              height: "1rem",
              backgroundColor: "#fff",
              borderRadius: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
              marginRight: "1rem",
            }}
          >
            <div
              style={{
                width: "0.6rem",
                height: "0.6rem",
                backgroundColor: !terms ? "#fff" : "#a3a3a3",
                borderRadius: 0,
                border: "none",
              }}
            />
          </div>
        </div>
  
        <div>
          <p
            style={{
              color: "white",
              fontSize: "0.5rem",
              textAlign: "left",
              lineHeight: "1.2",
              letterSpacing: "0.06rem",
            }}
          >
            By submitting this form, you agree to receive recurring automated promotional and personalized marketing messages from Coach via the communication channel used when signing up. Consent is not a condition of any purchase. View
            <span
              style={{
                textDecoration: "underline",
                fontSize: "0.55rem",
              }}
  
              onClick={() => {
                window.open("/term.pdf", "_blank");
              }}
            >
              {" "}
              terms
            </span>
  
            <span
              style={{
                fontSize: "0.55rem",
              }}
            >
              {" "}
              &
            </span>
  
            <span
              style={{
                textDecoration: "underline",
                fontSize: "0.55rem",
              }}
  
              onClick={() => {
                window.open("https://malaysia.coach.com/security-and-privacy", "_blank");
              }}
            >
              {" "}
              privacy
            </span>
            .
          </p>
        </div>
      </section>
    );
  }
  
export function Input({
    placeholder = "FIRST NAME",
    size = "100%",
    type = "text",
    maxLength = 4,
    onChange,
  }) {
    return (
      <input
        placeholder={placeholder}
        type={type}
        name={placeholder}
        style={{
          textAlign: "center",
          fontSize: "0.8rem",
          color: "black",
          border: "none",
          backgroundColor: "white",
          width: size,
          borderRadius: "0",
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
          marginBottom: "0.5svh",
        }}
        onChange={onChange}
      />
    );
}

const Preload = ()=>{
  return (
    <div
      style={{
        position : "absolute",
        top : "50%",
        left : "50%",
        transform : "translate(-50%,-50%)",
        zIndex : 1000,
        width : "102svw",
        height : "100svh",
        transition : "0.3s",
        backgroundColor : "white",
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        backgroundImage : "url('/2d/preloading.webp')",
        backgroundSize : "cover",
        backgroundPosition : "center",
        backgroundRepeat : "no-repeat",
      }}

      id="preload"
    >
        <div
          className='imgContainer-h float'
          style={{
            height : "17svh",
            margin : "auto"
          }}
        >
          <img src='/2d/logo.webp' className='logo'/>
        </div>    
    </div>
  )
}

export const LegerLine = () => {
  return (
    <div
      className="footer"
      style={{
        position: "absolute",
        bottom: "1.5%",
        left: "50%",
        zIndex: 10000000000000,
        transform: "translate(-50%,0)",
        width: "100vw",
        textAlign: "center",
        opacity: 1,
        zIndex: 10000,
      }}
    >
      <span
        className="mt-auto"
        style={{
          fontSize: "0.5rem",
          color: "white",
          fontFamily: "HelveticaLTPro-Roman",
        }}
      >
        <span
          style={{
            fontSize: "0.65rem",
            position: "relative",
            top: "-0.03svh",
            marginRight: "0.5vw",
          }}
        >
          &reg;
        </span>
        {"  "} ALL RIGHTS RESERVED BY COACH. POWERED BY{" "}
        <a
          style={{
            color: "white",
            fontSize: "0.53rem",
            fontFamily: "HelveticaLTPro-Roman",
            textDecoration: "underline",
          }}
          href="https://www.instagram.com/conten.tech/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          CONTEN.T
        </a>
      </span>
    </div>
  );
};

export const LegerLineBig = () => {
  return (
    <div
      className="footer"
      style={{
        position: "absolute",
        bottom: "1.5%",
        left: "50%",
        zIndex: 10000000000000,
        transform: "translate(-50%,0)",
        width: "100vw",
        textAlign: "center",
        opacity: 1,
        zIndex: 10000,
      }}
    >
      <span
        className="mt-auto"
        style={{
          fontSize: "1rem",
          color: "white",
          fontFamily: "HelveticaLTPro-Roman",
        }}
      >
        <span
          style={{
            fontSize: "1.35rem",
            position: "relative",
            top: "-0.03svh",
            marginRight: "0.5vw",
          }}
        >
          &reg;
        </span>
        {"  "} ALL RIGHTS RESERVED BY COACH. POWERED BY{" "}
        <a
          style={{
            color: "white",
            fontSize: "1rem",
            fontFamily: "HelveticaLTPro-Roman",
            textDecoration: "underline",
          }}
          href="https://www.instagram.com/conten.tech/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          CONTEN.T
        </a>
      </span>
    </div>
  );
};


export const DesktopBlock = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100svh",
        backgroundImage: "url(/2d/bg.jpg)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage: "url(/2d/bg.jpg)",
        backgroundPosition: "center",
        backgroundColor : "orange",
        overflow : "hidden",
        position : "relative",
      }}
    >
      <div
        className="imgContainer-h"
        style={{
          height: "80svh",
          width: "fit-content",
          position: "absolute",
          left : "0",
          top : "50%",
          transform : "translate(-40%,-50%)",
        }}
      >
        <img src="/dsk_left.png"/>
      </div>
      

      <div
        className="imgContainer-h"
        style={{
          height: "80svh",
          width: "fit-content",
          position: "absolute",
          left : "90%",
          top : "50%",
          transform : "translate(-40%,-50%)",
        }}
      >
        <img src="/dsk_right.png"/>
      </div>


      <div
        className="block"
        style={{
          height: "12svh",
          //6
        }}
      />
      <div
        style={{
          height: "15svh",
          width: "fit-content",
          margin: "auto",
        }}
      >
        <img src="/2d/logo.webp" style={{ height: "100%" }} />
      </div>

      <div
        className="block"
        style={{
          height: "3svh",
        }}
      />

      <h2
        style={{
          lineHeight: "1.5",
          fontSize: "2svh",
          margin: "auto",
          width: "fit-content",
        }}
      >
        THIS EXPERIENCE IS ONLY AVAILABLE ON MOBILE <br />
        PLEASE SCAN THE QR CODE BELOW TO ACCESS
      </h2>

      <div
        className="imgContainerHeight"
        style={{
          height: "37svh",
          width: "fit-content",
          margin: "auto",
          padding: "3svh",
          zIndex: 100,
        }}
      >
        <img
          src="/2d/QR.jpg"
          style={{
            height: "100%",
            zIndex: 100,
          }}
        />
      </div>
      <div
        className="block"
        style={{
          height: "3svh",
        }}
      />
      <div
        className="block"
        style={{
          height: "4svh",
        }}
      />
    
      <LegerLineBig />
    </div>
  );
};

  
export {
    Button,
    Preload,
}

