//import { Routes, Route, BrowserRouter } from 'react-router-dom';
import '../App.css';
import Wraper from "./common/wraper";
import { Button } from "./common";
import PopupLocation from "./common/popupLocation";
import PopupNotyet from "./common/popupNotyet";
import { useState } from 'react';
import { motion } from "framer-motion"
import { useSuperfan } from '../context';
import Lottie from "lottie-react";
import Tap from "./common/tap.json";

const Index = () =>{
    const [active , setActive] = useState(null);
    const [imgActivate , setImgActivate] = useState(false);
    const [alreadyActivate , setAlreadyActivate] = useState(false);

    const {} = useSuperfan();
    return(
      <Wraper
        onClick = {()=>{
          setImgActivate((prev)=>{
            if(prev === 1 || prev === 2){
              try{
                if(window.canplay){
                  document.querySelector("#Map-Close").play()
                }
              }
              catch(e){
                  console.log(e);
              }
              return null;
            }
            return prev;
          });
        }}
      >
        <div className='block' style={{
          height : "4.5%"
        }}/>
        <div
          className='imgContainer-h'
          style={{
            height : "14svh",
            margin : "auto"
          }}
        >
          <img src='/2d/logo.webp' className='logo'/>
        </div>

        <div className='block' style={{
            height: "2svh"
        }}/>
      
        <div
          className='imgContainer-h'
          style={{
            height : "1.8rem",
            margin : "auto"
          }}
        >
          <img src='/word1.webp'/>
        </div>

        <div className='block' style={{
          height: "2svh"
        }}/>

        <p
          style={{
            lineHeight : "1.2",
          }}
        >
          to Coach Play Ipoh, where an oasis of adventure<br/>
           awaits! Visit us, play a game, and win an exclusive<br/>
            gift. Limited time only!
        </p>

          
        <div className='block' style={{
          height: "3svh"
        }}></div>

        {/* img1 */}
        <div
          style={{
            display : "flex",
            height : "fit-content",
            justifyContent : "center",
            alignItems : "center",
            userSelect : "none"
          }}
        >
          <div className="imgContainer-h"
            style={{
              height : "12svh",
              maxHeight : "8rem"
            }}
          >
            <img src='/2d/word1.webp' style={{pointerEvents : "none"}}/>
          </div>
          <div className='block' style={{
          width: "5%"
        }}></div>
          <div
            style={{
              height : "18svh",
              width : "25svh",
            }}
          >
            <motion.div className="imgContainer-h"
              style={{
                height : "18svh",
                transform : "rotate(10deg)",
                position : "absolute",
              }}

              onClick={(event)=>{
                event.stopPropagation();
                setAlreadyActivate(true);
                setImgActivate((prev)=>{
                  if(prev === 1){
                    try{
                      if(window.canplay){
                        document.querySelector("#Map-Close").play()
                      }
                    }
                    catch(e){
                        console.log(e);
                    }
                    return null
                  }
                  try{
                    if(window.canplay){
                      document.querySelector("#Map-Open").play()
                    }
                  }
                  catch(e){
                      console.log(e);
                  }
                  return 1;
                
                });
              }}

              animate = {imgActivate === 1 ? {
                height : "55svh",
                zIndex : 1,
                transform : "rotate(0deg)",
                left : "-15.8%",
                top : "15%",
              } : 
                {
                  // animate keep rotate
                  rotate : [10 , 12 , 10],
                  transition : {
                    duration : 3,
                    repeat : Infinity,
                    type : "linear"
                  }
                }
              }
            >
              <img src='/2d/map.webp' style={{pointerEvents : "none"}}/>
              <Pin isOn = {imgActivate === 1}/>

              <div
                style={{
                  position : "absolute",
                  width : "fit-content",
                  height : "fit-content",
                  right : "0%",
                  bottom : "0%",
                  transform : "translate(30% , 25%)",
                  opacity : alreadyActivate ? 0 : 1,
                  transition : "opacity 1s",
                  pointerEvents : "none"
                }}
              >
                <Lottie animationData={Tap} loop={true}
                  style={{
                    height : "15svh",
                    width : "15svh"
                  }}
                />
              </div>

            </motion.div>

          </div>
          
        </div>
        <div className='block' style={{
          height: "3svh"
        }}></div>

                {/* img2 */}
        <div
          style={{
            display : "flex",
            height : "fit-content",
            justifyContent : "space-between",
            alignItems : "center",
            padding : "0 22%",
            marginTop : "-6svh"
          }}
        >
          <motion.div className="imgContainer-h"
            style={{
              height : "22svh",
              transform : "rotate(-15deg)",
              maxHeight : "13rem"
            }}

            onClick={(event)=>{
              event.stopPropagation();
              setImgActivate((prev)=>{
                if(prev === 2){
                  return null
                }
                return 2;
              });
            }}

            animate = {imgActivate === 2 ?
              null 
            //   {
            //   transform : "rotate(0deg) scale(1.7) translate(40% , -35%)",
            //   zIndex : 1
            // } 
            : {
            }}
          >
            <img src='/2d/mobile.webp'/>
          </motion.div>


          <div className="imgContainer-h"
            style={{
              height : "12svh",
              maxHeight : "8rem",
              marginRight : "3svh",
              marginTop : "3svh"
            }}
          >
            <img src='/2d/word2.webp'/>
          </div>
        </div>
        <div className='block' style={{
          height: "4svh"
        }}></div>


        <Button
          _id = "startPage"
          onClick = {()=>{
            setActive(1)
          }}
        >
          PLAY THE GAME
        </Button>

        <div className='block' style={{
          height: "2svh"
        }}></div>

        <Button
          to = "/page3"
          _id = "locateus"
          onClick={()=>{
            window.open("https://malaysia.coach.com/coach-play-ipoh", "_blank")
          }}
        >
          LOCATE US
        </Button>

        {
          active === 1 && <PopupLocation setActive = {setActive}/>
        }

        {
          active === 2 && <PopupNotyet setActive = {setActive}/>
        }
     </Wraper>
    )
}

const Pin = ({isOn})=>{
  return (
    <motion.div
      style={{
        height : "3svh",
        position : "absolute",
        top : "68%",
        left : "53%",
      }}

      animate = {isOn ? {
        height : "8svh",
      } : null}

      transition={ isOn ? {
      } : {

      }}

      className={`imgContainer-h ${isOn ? "float" : ""}`}

      onClick={(event)=>{
        event.stopPropagation();
        // https://maps.app.goo.gl/FpW9QcNtrBCMbzrh7
        window.open("https://www.google.com/maps/dir/3.1031296,101.4530048/coach+play+ipoh/@3.7968603,100.6647865,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x31cae70b7ea325c7:0x92d7a61d46f25a6!2m2!1d101.052614!2d4.4597354?entry=ttu", "_blank")
      }}
    >
      <img src='/2d/point.png' style={{pointerEvents : "none"}}/>
    </motion.div>
  )
}

export default Index;