import '../App.css';
import Wraper from "./common/wraper";
import { Button } from "./common"
import { transform } from 'framer-motion';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Index = () =>{
    const [isReedeemed , setIsReedeemed] = useState(false);
    const nav = useNavigate();
    useEffect(()=>{
        let redeemed = localStorage.getItem("redeemed");
        if(redeemed){
            setIsReedeemed(true);
        }
    },[])
    return(
        <Wraper>
            <div className='block' style={{
                height : "15.5%"
                }}/>
                <div
                className='imgContainer-h'
                style={{
                    height : "18svh",
                    margin : "auto"
                }}
                >
                <img src='/2d/logo.webp' className='logo'/>
            </div>

            <div className='block' style={{
                height : "7.5%"
                }}/>

            <h4>
                THANK YOU<br/>
                FOR PARTICIPATING!
            </h4>
            <div className='block' style={{
                height : "2.5%"
            }}/>

            <p>
                Play again or browse our latest collection.
            </p>

            <div className='block' style={{
                height : "2.5%"
            }}/>

            <Button
                _id = "playAgain"
                onClick = {()=>{
                    localStorage.removeItem("item1");
                    localStorage.removeItem("item2");
                    localStorage.removeItem("item3");

                    nav("/");
                }}            
            >
                PLAY AGAIN
            </Button>

            <div className='block' style={{
                height : "2.5%"
            }}/>

            <Button
                _id="shopCollection"
                onClick = {()=>{
                    window.open("https://malaysia.coach.com/coach-play-ipoh", "_blank")
                }}
            >
                COACH.COM
            </Button>

            {!isReedeemed && <PopUp setIsReedeemed = {setIsReedeemed}/>}
        </Wraper>
    )
}

const PopUp = ({setIsReedeemed})=>{
    const [action , setAction] = useState(1);
    const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
    const date = new Date();
    const day = date.getDate();
    const day_detail = days[date.getDay()];
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const final_hours = hours < 10 ? "0" + hours : hours;
    const final_minutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedDate = `${day_detail} ${month} ${day} ${year} ${final_hours}:${final_minutes}:${seconds}`;

    return (
        <div style={{
            backgroundColor: "#dea42b",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            height: "88svh",
            width : "90%",
            border : "solid 0.3rem white",
            borderRadius: "15px",
            color: "white",
            padding : "0 12%",

        }}>

            <div className='block' style={{
                height : "5.5%"
            }}/>

            <div
                className='imgContainer-h'
                style={{
                    height : "11svh",
                    margin : "auto",
                }}
                >
                <img src='2d/logo.webp' className='logo'/>
            </div>

            <div className='block' style={{
                height : "3.5%"
            }}/>

            <h5
                style={{
                    color : "#FFF4CC"
                }}
            >
                You got a set of limited-edition<br/>
                Coach Play Ipoh Stickers!
            </h5>

            <div className='block imgContainer-h' style={{
                height : "40%",
                width : "100%",
                position : "relative"
            }}>
                <img src='/2d/stickers.webp'/>
            </div>
            <div className='block' style={{
                    height : "3%"
            }}/>

            <h5
                style={{
                    color : "#FFF4CC",
                }}
            >
                Don’t tap the button below!<br/>
                Present it to staff to redeem.
            </h5>
            
            <div className='block' style={{
                    height : "3%"
            }}/>

            <button style={{
                color: "white",
                backgroundColor: ["#1eae35" , "#CD450A" , "#9B9696"][action-1],
                paddingTop : "0.8rem",
                paddingBottom : "0.7rem",
                width : "12rem",
                border: "none" , 
                
            }}
                onClick={()=>{
                    setAction((prev)=>{
                        if(prev < 3){
                            return prev + 1;
                        }

                        return prev;
                    })

                    if(action >= 3){
                        localStorage.setItem("redeemed" , true);
                        setIsReedeemed(true);
                    }
                }}
            >
                {action === 1 && "STAFF REDEEM"}
                {action === 2 && "STAFF CONFIRM"}
                {action === 3 && "REDEEMED"}
            </button>

            <div className='block' style={{
                    height : "3%"
                    }}/>

            <h6
                style={{
                    color : "#FFF4CC"
                }}
            >
                {formattedDate}<br/>
                GMT +0800 (MALAYSIA TIME)
            </h6>
        </div>
    )
}

export default Index;