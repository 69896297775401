import './App.css';
import THREECANVAS from "./3d"
import { useEffect, useRef } from 'react';
import { Routes, Route , useLocation } from "react-router-dom";
import { Page1 ,  Page2 , Page3 , Page4 , Page5 } from "./pages"
import BgVideo from "./bgvideo";
import { LegerLine } from "./pages/common";
import Speakerrr from "./pages/common/speaker"

function App() {
  const location = useLocation();
  useEffect(() => {
    if(location.pathname !== "/page4"){
      try{
        window?.transitionScene2()
        let bgcontainer = document.getElementById("bg-video-container");
        bgcontainer.style.zIndex = "-1";
        bgcontainer.style.opacity = "1";
      }catch(e){
      }
    }
}, [location]);

  
  return (
    <div className="App"
      style={{
        width : "100svw",
        height : "100svh",
        touchAction : "none",
        position : "fixed",
      }}
    >

      <Routes>
        <Route path="/" element={<Page1 />} />
        <Route path="/page2" element={<Page2 />} />
        <Route path="/page3" element={<Page3 />} />
        <Route path="/page4" element={<Page4 />} />
        <Route path="/page5" element={<Page5 />} />
      </Routes>


      <Speakerrr/>
      <THREECANVAS/>
      <LegerLine/>
      <BgVideo/>
    </div>
  );
}




export default App;
